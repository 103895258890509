import { playVideo } from "./video";

async function modal() {
    var videoPopups = document.querySelectorAll(".video-element-play");

    if (videoPopups) {
        var modal = document.getElementById("video-popup");
        var span = document.getElementsByClassName("popup__close")[0];
        var videoPopupParent = modal.querySelector('.video-element');
        var videoPopupIframe = modal.querySelector('.video-element__video iframe');
        var videoPopupVideo = modal.querySelector('.video-element__video video');
        var videoPopupPlay = modal.querySelector('.video-element .video-element__play');
        span.onclick = function () {
            modal.classList.remove('is-visible');
            setTimeout(function () {
                modal.style.display = "none";
            }, 500);
        }
        window.onclick = function (event) {
            if (event.target == modal) {
                modal.classList.remove('is-visible');
                videoPopupIframe.setAttribute('src', '');
                setTimeout(function () {
                    modal.style.display = "none";
                }, 500);
            }
        }
        document.addEventListener("touchstart", function (event) {
            if (event.target == modal) {
                modal.classList.remove('is-visible');
                videoPopupIframe.setAttribute('src', '');
                setTimeout(function () {
                    modal.style.display = "none";
                }, 500);
            }
        });
        videoPopups.forEach(videoPopup => {
            videoPopup.parentElement.addEventListener('click', event => {
                event.preventDefault();
                modal.style.display = "block";

                var videoId = videoPopup.getAttribute('data-video-id');
                var videoElementId = videoPopup.getAttribute('data-video-element-id');
                var videoPlayer = videoPopup.getAttribute('data-video-player');

                if (videoPlayer === 'youtube') {
                    videoPopupIframe.id = videoElementId;
                    videoPopupIframe.setAttribute('video-src', 'https://www.youtube.com/embed/' + videoId + '?autoplay=1');
                    videoPopupIframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
                } else if (videoPlayer === 'vimeo') {
                    videoPopupIframe.id = videoElementId;
                    videoPopupIframe.setAttribute('video-src', 'https://player.vimeo.com/video/' + videoId + '?autoplay=1');
                    videoPopupIframe.setAttribute('allow', 'autoplay; fullscreen');
                } else {
                    videoPopupIframe.style.display = "none";
                    videoPopupVideo.style.display = "block";
                    videoPopupVideo.querySelector('source').id = videoElementId;
                    videoPopupVideo.querySelector('source').setAttribute('video-src', videoId);
                    videoPopupVideo.load();
                }

                videoPopupPlay.setAttribute('data-id', videoElementId);

                videoPopupParent.classList.remove('not-loaded');

                playVideo(true);

                setTimeout(function () {
                    modal.classList.add('is-visible');
                }, 20);
            });
        });
    }
} export {
    modal
}