

const formSubmitSingle = () => {
    // var input = document.getElementById("myInput");
    var formsSingleInputs = document.querySelectorAll(".contact-form form .single-field-form .form-field input");
    if (typeof (formsSingleInputs) != 'undefined' && formsSingleInputs != null) {
        formsSingleInputs.forEach((input) => {
            var getParent = input.closest('.contact-form'),
                getParentForm = getParent.querySelectorAll('form'),
                getButton = getParent.querySelector('button.button'),
                getLink = getParent.querySelector('div.button a');

            // console.log(getParent, getButton, getLink, {getParentForm});
            input.addEventListener("keypress", function (event) {
                if (event.key === "Enter") {
                    // alert('Enter')
                    event.preventDefault();
                    if (getLink) {
                        // console.log({getLink});
                        getLink.click();
                    } else if (getButton) {
                        // console.log({getButton})
                        if (typeof (getParentForm) != 'undefined' && getParentForm != null) {
                            getParentForm.forEach((form) => {
                                form.submit();
                                form.addEventListener('submit', function (e) {
                                    e.preventDefault()
                                    executeRecaptcha(form, function () {
                                        form.submit();
                                    })
                                });
                            });
                        }
                    }
                }
            });
        });
    }
}

const formSubmit = () => {

    var forms = document.querySelectorAll(".contact-form form");
    if (typeof (forms) != 'undefined' && forms != null) {
        forms.forEach((form) => {
            form.addEventListener('submit', function (e) {
                e.preventDefault()

                executeRecaptcha(form, function () {
                    form.submit();
                })
            });
        });
    }
}

export {
    formSubmit, formSubmitSingle
}