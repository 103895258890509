const searcher = () => {
	var searchs = document.querySelectorAll('.search-btn');
	if (typeof (searchs) != 'undefined' && searchs != null) {
		searchs.forEach((search) => {
			search.querySelector('.search-icon').addEventListener('click', event => {
				search.classList.toggle('active');
			});
		})
	}
} 

export {
    searcher
}