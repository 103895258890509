var $ = require("../../../config/node_modules/jquery");

async function digiBronSearch() {

    $("input.digi").change(function (e) {
        let digiBronInput = $(".digibron");
        let digiBronInputHref = 'https://irs.digibron.nl/zoeken/zoekwoord/';


        var value = this.value;
        var digiBronInputHrefNew = digiBronInputHref.concat(value);
        console.log(digiBronInputHrefNew);

        $(digiBronInput).attr('href', digiBronInputHrefNew);
    });
} export {
    digiBronSearch
}
