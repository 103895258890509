var $ = require("../../../config/node_modules/jquery");

$('.navbar-toggler').click(function() {

    $('.navigation').toggleClass('navigation-open');
    $(".main-content-wrapper").toggleClass("pushed");
    $('body').toggleClass('lockScroll');
    $('.navbar-collapse').toggleClass('show');
});


$(document).ready(function () {
    // instagramFeed();

    const setActive = (el, active) => {
        const formField = el.parentNode;
        if (active) {
            formField.classList.add('form-field--is-active');
        } else {
            formField.classList.remove('form-field--is-active');
            el.value === '' ?
                formField.classList.remove('form-field--is-filled') :
                formField.classList.add('form-field--is-filled');
        }
    };

    [].forEach.call(
        document.querySelectorAll('.form-field__label, .form-field__input, .form-field__textarea'),
        el => {
            el.onblur = () => {
                setActive(el, false);

            };
            el.onfocus = () => {
                setActive(el, true);
            };
        });
});


$(window).scroll(function() {
    var scroll = $(window).scrollTop();

    if (scroll >= 40) {
        $("#navigation").addClass("fixed_menu");
        $(".main-content-wrapper").addClass("less-padding");
    }
    else {
        $("#navigation").removeClass("fixed_menu");
        $(".main-content-wrapper").removeClass("less-padding");
    }
});


if (navigator.userAgent.match(/(\(iPod|\(iPhone|\(iPad)/)) {
    $(document).on('click touchend', function(event) {
    if (event.type === "click") detectTap = true; //detects click events
    if (detectTap){
        let link = $('.getuigenis-item, .button, .lectuur_categorie, .uitgelicht-item '); //preselect the link
        //here you can write the function or codes you wanna execute on tap
        link.addClass("hover");
        $(link).not(this).removeClass("hover");
    }
});

} else {
    $('.getuigenis-item, .button, .lectuur_categorie, .uitgelicht-item ').on("touchmove", function (e) {
        "use strict"; //satisfy the code inspectors
        var link = $(this); //preselect the link
        if (link.hasClass('hover')) {
            return true;
        } else {
            link.addClass("hover");
            $('.getuigenis-item, .button, .lectuur_categorie, .uitgelicht-item ').not(this).removeClass("hover");
            e.preventDefault()

            return false; //extra, and to make sure the function has consistent return points
        }
    });
}
