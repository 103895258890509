async function donationForm(thisContainer) {
	const forms = thisContainer.querySelectorAll(".donation-form");

	if (typeof (forms) === 'undefined' || forms === null) return;

	const invalidChars = [
		"-",
		"+",
		"e",
		"E",
		".",
		" ",
	];


	for (var i = 0; i < forms.length; i++) {
		let form = forms[i],
				steps = form.querySelectorAll('.step'),
				prevBtn = form.querySelector('.donation-form__button[data-prev]'),
				submitBtn = form.querySelector('button[type="submit"]');


		// --------------------------- First step ---------------------------
		const amountInputs = form.querySelectorAll('input[name="amount"]'),
			amountCustomInput = form.querySelector('#amount_custom_input'),
			amountCustomOutput = form.querySelector('#amount_custom');

		amountInputs.forEach(input => {
			input.addEventListener('change', () => {
				if (!amountCustomOutput.checked) {
					amountCustomInput.value = '';
				}
			});
		});

		amountCustomInput.addEventListener('keydown', (e) => {
			if (invalidChars.includes(e.key)) {
				e.preventDefault();
			}
		});

		amountCustomInput.addEventListener('change', (evt) => {
			amountCustomOutput.checked = true;
			amountCustomOutput.value = amountCustomInput.value;
		});

		// --------------------------- Next/Submit button ---------------------------
		prevBtn.addEventListener('click', () => {
			let prev = prevBtn.dataset.prev;
			let texts = submitBtn.querySelectorAll('.text-small');

			texts.forEach(text => {
				text.classList.add('dn');
			});

			steps.forEach(step => {
				step.classList.add('dn');
			});

			steps[prev].classList.remove('dn');
			texts[prev].classList.remove('dn');
			prevBtn.classList.add('dn');
			submitBtn.dataset.next = parseInt(prev) + 1;
		});

		// --------------------------- Next/Submit button ---------------------------
		submitBtn.addEventListener('click', function (e) {
			let next = this.dataset.next;
			e.preventDefault();
			
			if (parseInt(next) === steps.length) {
				beforeSubmit();
			} else {
				let texts = this.querySelectorAll('.text-small');

				texts.forEach(text => {
					text.classList.add('dn');
				});

				steps.forEach(step => {
					step.classList.add('dn');
				});

				prevBtn.classList.remove('dn');
				steps[next].classList.remove('dn');
				texts[next].classList.remove('dn');

				this.dataset.next = parseInt(next) + 1;
			}
		});

		// --------------------------- Form submit ---------------------------
		function beforeSubmit() {
			let inputs = steps[1].querySelectorAll('.form-field__input, .form-field__avg');
			let errors = [];
			
			for (var i = 0; i < inputs.length; i++) {
				inputs[i].classList.remove('invalid');

				if (validate(inputs[i]).length > 1) {
					errors.push(validate(inputs[i]));
				}

                console.log(errors)

				if (i == (inputs.length - 1)) {
					if (errors.length == 0) {
						form.submit();
					} else {
						for (var i = 0; i < errors.length; i++) {
							errors[i][0].classList.add('invalid');
						}
					}
					break;
				}
			}
		}
	}


	function validate(input) {
		let errorArray = [input];
		var inputName;

		if (input.name.includes('default_fields')) {
			inputName = input.name.replace("default_fields[", "").replace("]", "");
		} else {
			inputName = input.name;
		}

		if (input.hasAttribute('required') && input.value == '') {
			errorArray.push(inputName + ' is nog niet ingevuld');
		}

		if (input.value != '') {
			let emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			let phoneRe = /^\d{10,}$/;
			// let ibanRe = /^([A-Z]{2}[ '+'\\'+'-]?[0-9]{2})(?=(?:[ '+'\\'+'-]?[A-Z0-9]){9,30}$)((?:[ '+'\\'+'-]?[A-Z0-9]{3,5}){2,7})([ '+'\\'+'-]?[A-Z0-9]{1,3})?$/;
			let ibanRe = /^NL[0-9]{2}[A-z0-9]{4}[0-9]{10}$/;
			if (input.getAttribute('type') === "email" && emailRe.test(input.value.toLowerCase()) != true) {
				errorArray.push(inputName + ' is nog niet correct ingevuld');
			}
			if (input.getAttribute('type') === "tel" && phoneRe.test(input.value.replace(' ', '').replace('-', '').toLowerCase()) != true) {
				errorArray.push(inputName + ' is nog niet correct ingevuld');
			}
			if (input.getAttribute('type') === "text" && input.getAttribute("name") === "iban" && ibanRe.test(input.value.replaceAll(' ', '')) != true) {
				errorArray.push(inputName + ' is nog niet correct ingevuld');
			}
			if (input.classList.contains('form-field__select') && input.hasAttribute('required') && input.options[input.options.selectedIndex].disabled == true) {
				errorArray.push(inputName + ' is nog niet geselecteerd');
			}
			if (input.classList.contains('form-field__avg') && input.hasAttribute('required') && input.checked != true) {
				errorArray.push(inputName + ' is nog niet geselecteerd');
			}
		}
		
		return errorArray;
	}

}

export {
	donationForm
}