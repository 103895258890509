import Plyr from '../../../config/node_modules/plyr';

var audioPlayer = () => {
    var Plyrs = document.querySelectorAll('.audioplayer');
	if (typeof(Plyrs) != 'undefined' && Plyrs != null) {
		Plyrs.forEach((PlyrsItm) => {
            var player = new Plyr(PlyrsItm.querySelector('audio'), {
                controls: [
                    'play', 
                    'current-time', 
                    'duration',
                    'progress', 
                    'mute', 
                    // 'volume',
                ]
            });
            console.log(player);
            window.player = player;
        })
    }
	
    console.log({Plyr});
} 

export {
    audioPlayer
}