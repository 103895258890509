async function playVideo(alreadyClicked) {
	let videos = document.querySelectorAll('.video-element-play');
	if (videos) {
		for (var i = 0; i < videos.length; i++) {
			let thisVideo = videos[i];
			if (alreadyClicked === true) {
				let targetId = thisVideo.getAttribute('data-id');
				let targetSource = document.getElementById(targetId).getAttribute('video-src');

				document.getElementById(targetId).setAttribute('src', targetSource);
				document.getElementById(targetId).parentElement.classList.add('video--loaded');
			} else {
				thisVideo.parentElement.addEventListener('click', function () {
					let targetId = thisVideo.getAttribute('data-id');
					let targetSource = document.getElementById(targetId).getAttribute('video-src');

					document.getElementById(targetId).setAttribute('src', targetSource);
					document.getElementById(targetId).parentElement.classList.add('video--loaded');
				})
			}
		}
	}
}
export {
	playVideo
}