import { debounce, setActive } from './functions';
var accounting = require('../../../config/node_modules/accounting/accounting');
var $ = require("../../../config/node_modules/jquery/dist/jquery");


async function changeProducts() {
	console.log()
	// const EURO = value => currency(value, { symbol: '€', decimal: ',', separator: '.' });

	var selectedFilters = [];
	var products = ' ';
	var postType;
	var container = document.querySelector("#total_price");
	var shipmentcontainer = document.querySelector("#shipment_price");
	var i = 0;
	var totalPrice = 0;
	var shipment = 0;
	var shipmentIsFilled = false;
	var productAmountOldChanged;

	if ($(container).length > 0) {
		function makeRequest(data, type) {
			var text = [];
			var i;
			for (i in data) {
				if (data.hasOwnProperty(i)) {
					text.push(i + "=" + encodeURIComponent(data[i]));
				}
			}
			var textState = [];
			var i;
			for (i in data) {
				if (data.hasOwnProperty(i)) {
					if (i != 'page') {
						textState.push(i + "=" + decodeURIComponent(data[i]));
					}
				}
			}
			text = text.join("&");
			textState = textState.join("&");
			var url = "/order?" + text;
			var pushStateUrl = "?" + textState;
			var loader = "<div class='loader'></div>";

			// window.history.pushState(null, null, pushStateUrl);

			var xhr = window.XMLHttpRequest ? new XMLHttpRequest : window.ActiveXObject ? new ActiveXObject("Microsoft.XMLHTTP") : (alert("Browser does not support XMLHTTP."), false);
			xhr.onreadystatechange = text;
			$(container).append(loader);
			xhr.open("GET", url, true);
			xhr.setRequestHeader("Content-Type", "application/json");
			xhr.send(text);
			xhr.onload = function () {
				setTimeout(function () {
					setTimeout(function () {
						// console.log(xhr.response);
						container.innerHTML = xhr.response;
						updateOrder();
						// console.log(shipment)
						// scrollCont.update();
					}, 50);
				}, 100);
			};
			xhr.onerror = function () {
				// console.log(xhr.response);
				// alert("error");
			};
		}

		var quantity = document.querySelectorAll('.product-price-quantity');
		// console.log(quantity)

		if (quantity !== null) {
			quantity.forEach((quantityInput) => {
				// console.log(quantityInput)
				// checkChange(quantityInput);
				var quantityInputItemButtons = '<div class="quantity-nav"><div class="quantity-button quantity-up"><span>+</span></div><div class="quantity-button quantity-down"><span>-</span></div></div>',
					quantityInputItem = quantityInput,
					input = quantityInputItem.querySelector('input[type="number"]');

				$(quantityInputItemButtons).insertAfter(input);

				var btnUp = quantityInputItem.querySelector('.quantity-up'),
					btnDown = quantityInputItem.querySelector('.quantity-down'),
					min = input.getAttribute('min'),
					max = input.getAttribute('max'),
					parent = quantityInputItem.parentNode,
					product = parent.parentNode.querySelector('.product-name').getAttribute('data-name');


				// console.log({input}, {btnUp}, {btnDown}, {min}, {max}, {parent}, {product});
				var currentValue = parseFloat(input.value);
				input.addEventListener('input', function () {
					var newValue = parseFloat(this.value);
					// console.log({ currentValue }, { newValue })
					if (newValue > currentValue) {
						addItem(input, parseInt(parent.querySelector('.product-price-money').getAttribute('data-price')), totalPrice, product, products, parent.parentNode, parseFloat(newValue), parseFloat(currentValue));
					} else if (newValue < currentValue) {
						removeItem(input, parseInt(parent.querySelector('.product-price-money').getAttribute('data-price')), totalPrice, product, products, parent.parentNode, parseFloat(newValue), parseFloat(currentValue));
					}
					return currentValue = parseFloat(newValue);
				});

				btnUp.addEventListener("click", function () {
					addItem(input, parseInt(parent.querySelector('.product-price-money').getAttribute('data-price')), totalPrice, product, products, parent.parentNode);
				});

				btnDown.addEventListener("click", function () {
					removeItem(input, parseInt(parent.querySelector('.product-price-money').getAttribute('data-price')), totalPrice, product, products, parent.parentNode);
				});
			});
		}

		function addItem(input, price, total, product, products, productCode, inputValue, olderValue) {
			var oldValue = parseFloat(input.getAttribute('value')),
				max = 9999,
				newVal;

				console.log({ oldValue })
			if (oldValue >= max) {
				newVal = max;
			} else if (inputValue) {
				newVal = parseFloat(inputValue);
			} else {
				newVal = oldValue + 1;
			}

			input.value = newVal;
			input.setAttribute('value', newVal);

			changeProductAmount(productCode, product, newVal, oldValue)
			$(input).trigger("change");

			changeTotal(parseInt(price * input.value), parseInt(price * oldValue), total, product, products, true, newVal, productCode);
		}

		function removeItem(input, price, total, product, products, productCode, inputValue, olderValue) {
			var oldValue = parseFloat(input.getAttribute('value')),
				min = 0,
				newVal;

			if (oldValue <= min) {
				newVal = min;
			} else if (inputValue) {
				newVal = parseFloat(inputValue);
			} else {
				newVal = oldValue - 1;
			}

			input.value = newVal;
			input.setAttribute('value', newVal);

			changeProductAmount(productCode, product, newVal, oldValue)

			$(input).trigger("change");

			changeTotal(parseInt(price * input.value), parseInt(price * oldValue), total, product, products, false, newVal, productCode);
		}

		function changeProductAmount(productItem, productInfo, productAmountNew, productAmountOld) {

			// console.log(productItem, productInfo, productAmount);
			console.log(productAmountNew, productAmountOld)
			// console.log(productAmountOldChanged);
			var dataAtt = "[data-product='" + productItem.dataset.product + "']",
				product = document.querySelector(dataAtt),
				productAmountOldChanged = '\"data-amount\":\"' + productAmountOld + '\"',
				productAmountNewChanged = '\"data-amount\":\"' + productAmountNew + '\"',
				dataAttChange = productItem.dataset.product.replace(productAmountOldChanged, productAmountNewChanged);

			// console.log(productInfo)
			// console.log({productInfo}, {productAmountOld}, {productAmountNew}, {productAmountOldChanged}, {productAmountNewChanged}, {dataAttChange})


			// console.log(productAmount, dataAttChange);
			product.setAttribute('data-product', dataAttChange);
			// console.log();
			return productAmountOldChanged;
		}

		// Calc total & products; 
		function changeTotal(value, prevvalue, total, product, products, add, amount, productParent) {
			// console.log()
			var total = parseInt(total - prevvalue + value);

			if (add == true && amount <= 1) {
				productParent.classList.add('active');
			} else if (add == false && amount == 0) {
				productParent.classList.remove('active');
			}

			var producteni = document.querySelectorAll('.product.active');
			var productInfomation = '';
			for (var i = 0; i < producteni.length; i++) {
				// console.log(JSON.stringify(producteni[i].dataset.product))
				productInfomation += JSON.stringify(producteni[i].dataset.product);
			}

			selectedFilters.total = total;
			selectedFilters.products = productInfomation;

			// console.log(selectedFilters.products)
			makeRequest(selectedFilters);

			totalPrice = total;
			return totalPrice;
		}
	}


	if (document.querySelector('.shipment')) {
		document.querySelector('.shipment').addEventListener('change', function (e) {
			// if (document.querySelector('.total-price').innerHTML)
			if (document.querySelector('.total-price').innerHTML.replace(/\s+/g, ' ').trim() == '€ 0,00') {
				addShipmentValue(false);
			} else {
				addShipmentValue();
			}
			// console.log(document.querySelector('.products-json').getAttribute('data-total'));
		});
	}


	function addShipmentValue(status) {
		// console.log(shipmentIsFilled)
		if (document.querySelector('.order-form').getAttribute('shipmentprice')) {
			if (status == false || document.querySelector('.form-field.shipment').querySelector('input:checked').value != 'Verzenden') {
				shipment = 0;
			} else {
				shipment = parseInt(document.querySelector('.order-form').getAttribute('shipmentprice'));
			}
		} else {
			shipment = 0;
		}
		// console.log(shipment, shipmentcontainer.querySelector('.shipment-price'))
		shipmentcontainer.querySelector('.shipment-price').innerHTML = accounting.formatMoney((shipment / 100), "€ ", 2, "", ",");
		document.querySelector('#order-shipment').value = accounting.formatMoney((shipment / 100), "€ ", 2, "", ",");
		// } else if ( && ) {

		// 	// shipmentIsFilled = true;
		// } else if (document.querySelector('.order-form').getAttribute('shipmentprice') && document.querySelector('.form-field.shipment').querySelector('input:checked').value != 'Verzenden') {
		// 	shipment = -Math.abs(parseInt(document.querySelector('.order-form').getAttribute('shipmentprice')));
		// 	// shipmentIsFilled = false;
		// } else {
		// 	shipment = 0;
		// 	// shipmentIsFilled = false;
		// }

		return shipment;
	}

	function updateOrder() {
		if (document.querySelector('.products-json')) {
			var productsInfo = document.querySelector('.products-json').getAttribute('data-json').replace('{', '').replace('}', ''),
				productOrderInput = document.querySelector('#order-items'),
				productsInfoTotal = document.querySelector('.products-json').getAttribute('data-total'),
				productOrderInputTotal = document.querySelector('#order-total');

			productOrderInput.value = productsInfo;
			productOrderInputTotal.value = productsInfoTotal;
			// console.log(productsInfoTotal)
			addShipmentValue(true);
		} else {
			addShipmentValue(false);
		}
	};
} export {
	changeProducts
}