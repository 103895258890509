// Import Swiper and modules
import {
	Swiper,
	Navigation,
	Pagination,
	Scrollbar,
	Controller,
	Mousewheel,
	Autoplay,
	Lazy,
} from '../../../config/node_modules/swiper';
Swiper.use([Navigation, Pagination, Scrollbar, Controller, Mousewheel, Lazy, Autoplay]);

const slider = () => {
	var homeSliders = document.querySelectorAll('#home-slider');
	if (typeof (homeSliders) != 'undefined' && homeSliders != null) {
		homeSliders.forEach((homeSlider) => {
			var slides = homeSlider.querySelectorAll('.swiper-slide');
			homeSlider = new Swiper(homeSlider, {
				direction: 'horizontal',
				loop: true,
				speed: 800,
				roundLengths: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				loopedSlides: slides.length,
				mousewheel: {
					forceToAxis: true,
					thresholdTime: 400,
					thresholdDelta: 4,
				},
				navigation: {
					nextEl: homeSlider.querySelector('.swiper-button-next'),
					prevEl: homeSlider.querySelector('.swiper-button-prev'),
				},
				pagination: {
					el: homeSlider.querySelector('.swiper-pagination'),
					clickable: true,
				},
				autoplay: {
					delay: 5000,
				},
				// breakpoints: {
				// 	0: {
				// 		spaceBetween: 32,
				// 	},
				// 	767: {
				// 		spaceBetween: 32,
				// 	},
				// 	1200: {
				// 		slidesPerView: 'auto',
				// 		spaceBetween: 32,
				// 	}
				// },
			});
		});
	}
}

export {
	slider
}