// import {scrollCont} from './scrollContainer';
import {debounce, throttle} from './functions';
var $ = require("../../../config/node_modules/jquery");

async function filter(currentContainer) {
	var selectedFilters = [];
	selectedFilters['page'] = 1;
	var taxonmyFilters = document.querySelectorAll(".filter-button");
	var postType;
	var theEnd = false;
	var appended = false;
	var container = document.getElementById("result__wrapper");
	var i = 0;


	if ($(container).length > 0) {

		function makeRequest(data, type) {

			var text = [];
			var i;
			for (i in data) {
				if (data.hasOwnProperty(i)) {
					text.push(i + "=" + encodeURIComponent(data[i]));
				}
			}
			var textState = [];
			var i;
			for (i in data) {
				if (data.hasOwnProperty(i)) {
					if (i != 'page') {
						textState.push(i + "=" + encodeURIComponent(data[i]));
					}
				}
			}
			text = text.join("&");
			textState = textState.join("&");
			var url = "/filters?" + text;
			var pushStateUrl = "?" + textState;
			var loader = "<div class='loader'></div>";

			window.history.pushState(null, null, null);

			var xhr = window.XMLHttpRequest ? new XMLHttpRequest : window.ActiveXObject ? new ActiveXObject("Microsoft.XMLHTTP") : (alert("Browser does not support XMLHTTP."), false);
			xhr.onreadystatechange = text;
			$(container).append(loader);
			xhr.open("GET", url, true);
			xhr.setRequestHeader("Content-Type", "application/json");
			xhr.send(text);
			xhr.onload = function() {
				setTimeout(function() {
					container.classList.add("fade-out");
					
					if (type == 'append') {
						container.innerHTML = container.innerHTML + xhr.response;
					} else {
						container.innerHTML = xhr.response;
					}
					if (!xhr.response.includes('+==THEEND==+')) {
						setTimeout(function() {
							appended = false;
						}, 500);
					} else {
						theEnd = true;
					}
					setTimeout(function() {
						$('.loader').remove();
						container.classList.remove('fade-out');
					}, 50);
				}, 100);
			};
			xhr.onerror = function() {
				alert("error");
			};
		}

		function loadNextPage(scrollY) {
			// console.log(appended);
			// console.log(theEnd);
			let resultElement = document.querySelector('#result__wrapper');
			let position = elementInViewport(resultElement, resultElement.offsetTop, resultElement.offsetHeight, 100, scrollY);
			if (position && !appended && !theEnd) {
				appended = true;
				selectedFilters['page'] = selectedFilters['page'] + 1;
				makeRequest(selectedFilters, 'append');
			}
		}

		// var loadMoreBtn = document.querySelector('.result__container .load-more a');
		// if (loadMoreBtn) {
		// 	loadMoreBtn.addEventListener('click', function (clickEvent) {
		// 		clickEvent.preventDefault();
		// 		this.classList.add('hide-btn');
		// 		loadNextPage(false);
		// 		clickedLoadMore = true;
		// 	});
		// }
		if (currentContainer.classList.contains('paginate_index') && !container.classList.contains('.items-end')) {
			var thisContainer;
			thisContainer = $(document);
			
			thisContainer.on('scroll', throttle(function (scrollEvent) {
				var scrollYOffset;
				scrollYOffset = window.pageYOffset;
				
				loadNextPage(scrollYOffset);
			}, 500))
		}

		if (taxonmyFilters !== null) {
			for (; i < taxonmyFilters.length; i++) {
				postType = taxonmyFilters[i].getAttribute("data-object");
				selectedFilters.data_object = postType;
				taxonmyFilters[i].addEventListener("click", debounce(function (e) {
					container.classList.remove("banner-one-column");
					container.classList.remove("banner-two-column");
					selectedFilters['page'] = 1;
					appended = false;
					theEnd = false;

					$(taxonmyFilters).removeClass('active');
					$(this).addClass('active');

					var parentTax = this.getAttribute("data-parent");
					var buttonId = this.getAttribute("value");
					selectedFilters[parentTax] = buttonId;

					makeRequest(selectedFilters);
				}, 100));
			}
		}

		$(function() {
			var paramFilters = [];
			// Filter based on parameters in URL
			var param_object = getUrlParameter('data_object');
			var param_categories = getUrlParameter('categories');

			if (param_object || param_categories) {

				paramFilters.data_object = param_object;

				if (param_categories !== undefined) {
					var categoriesElement = $('*[value="' + param_categories + '"]');

					paramFilters.categories = param_categories;

					$(taxonmyFilters).removeClass('active');
					$(categoriesElement).addClass('active');
				}

				makeRequest(paramFilters);
			}

		});

		// Get parameters from URL
		function getUrlParameter(sParam) {
			var sPageURL = window.location.search.substring(1),
				sURLVariables = sPageURL.split('&'),
				sParameterName,
				i;

			for (i = 0; i < sURLVariables.length; i++) {
				sParameterName = sURLVariables[i].split('=');

				if (sParameterName[0] === sParam) {
					return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
				} else {}
			}
		}
	}

	function elementInViewport(el, top, height, offset, scrollY) {

		while (el.offsetParent) {
			el = el.offsetParent;
			top += el.offsetTop;
		}

		if (scrollY === false) {
			return true;
		} else {
			return (
				(scrollY + window.innerHeight) > (height + top - offset)
			);
		}
	}
} export {
	filter
}