import '../../config/node_modules/regenerator-runtime/runtime';
import './scripts/functions';
import './scripts/lazyload';
import './scripts/updatecss';
import './scripts/index';
import { modal } from './scripts/modal';
modal();
// import { playVideo } from './scripts/video';
// playVideo();
import {slider} from './scripts/swiper';
slider();
import {searcher} from './scripts/search';
searcher();
import {changeProducts} from './scripts/products';
changeProducts();
import {formSubmitSingle} from './scripts/form';
formSubmitSingle();
// formSubmit();
import {filter} from './scripts/filter';
filter(document.querySelector('body'));
// import {formGeneral, donateForm} from './scripts/donateForm';
// formGeneral();
// donateForm();
import { donationForm } from './scripts/donateForm';
donationForm(document);
import { digiBronSearch } from './scripts/digiBronSearch';
digiBronSearch();
import { audioPlayer } from './scripts/plyr';
audioPlayer();

import './styles/style';